.welcomeText {
  margin: 12vw 0vw 0vw 4vw;
}

h1 {
  margin-top: 20vw;
  font-size: 3rem;
  font-weight: normal;
}

.meKg {
  font-size: 1.75rem;
  padding-top: 0;
  margin-block-end: 0em;
  margin-block-start: 0em;
  width: 65%;
}

.homeButton a {
  text-decoration: none;
  border: black 1px solid;
  font-size: 1.25rem;
  padding: 1.5vh;
  margin-right: 3vw;
  text-decoration: none;
  color: #000000;
}

.homeButtonContainer {
  padding-bottom: 5vh;
}

.homeButton a:hover {
  background-color: black;
  color: white;
  text-decoration-line: underline;
  text-decoration-style: wavy;
}

.homeButtonOuter {
  display: flex;
  flex-direction: column;
  margin-top: 7vh;
  color: #000000;
}

@media only screen and (min-width: 600px) {
  .welcomeText {
    margin: 4vw 0vw 0vw 4vw;
  }

  h1 {
    margin-top: 10vw;
    font-size: 3rem;
    font-weight: normal;
  }

  .meKg {
    font-size: 2rem;
    padding-top: 0;
    margin-block-end: 0em;
    width: 80%;
  }

  .homeButton a {
    text-decoration: none;
    border: black 1px solid;
    font-size: 1rem;
    padding: 1.5vh;
    margin-right: 3vw;
    text-decoration: none;
    color: #000000;
  }

  .homeButtonOuter {
    display: flex;
    flex-direction: row;
    margin-top: 6vh;
    color: #000000;
  }
}

@media only screen and (device-width: 768px) {
  .homeButtonOuter {
    display: flex;
    flex-direction: column;
    margin-top: 6vh;
    color: #000000;
  }
}
