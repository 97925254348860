@import url(//db.onlinewebfonts.com/c/cbf9b417fc15851ba870990e585e35a2?family=DIN);
@font-face {
  font-family: "dinlight";
  src: url(/static/media/DIN-Light.c1db7e39.eot);
  src: url(/static/media/din_light_regular-webfont.aec0f6ed.woff2) format("woff2"),
    url(/static/media/din_light_regular-webfont.113c072b.woff) format("woff"),
    url(/static/media/DIN-Light.c1db7e39.eot?#iefix) format("eot"),
    url(/static/media/DIN-Light.6c3be687.woff) format("woff"),
    url(/static/media/DIN-Light.d96da7a7.ttf) format("truetype"),
    url(/static/media/DIN-Light.3f830cd7.svg#DIN-Light) format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "dinlight", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background-color: white;
  overflow: hidden auto;
}

body {
  min-height: 100vh;
  overflow: hidden auto;
}

h1 {
  margin-block-end: 1vw;
}

a {
  text-decoration: none;
}

.welcomeText {
  width: 75%;
}

.aboutText {
  display: flex;
  flex-direction: column;
  margin-top: 8vw;
}

.aboutText a:hover {
  color: #000000;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: wavy;
          text-decoration-style: wavy;
}

.aboutText a {
  color: #000000;
  text-decoration: none;
}

.aboutLines {
  padding-bottom: 4vh;
}

.social {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  margin-top: 6vw;
}

.social a #icon {
  width: 4vh;
  height: 4vh;
  padding: 2vw;
  text-align: center;
  line-height: 32px;
  border-radius: 32px;
  display: block;
  background-color: black;
  color: white;
  font-size: 12px;
}

#cvButton img {
  width: 12.5vw;
}

#cvIcon:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.social > a {
  margin-right: 4vw;
}

.social a #icon:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

@media only screen and (min-width: 600px) {
  #cvButton img {
    width: 3vw;
  }

  .aboutText {
    margin-top: 3vw;
  }

  .welcomeText {
    width: 55%;
  }

  .social {
    margin-top: 2vw;
  }

  .social > a > #icon {
    width: 3vh;
    height: 3vh;
    padding: 9px;
  }

  .social > a {
    margin-right: 2vw;
  }
}

.designContainer {
  margin: 12vw 4vw 0vw 4vw;
  margin-top: 20vw;
  font-size: 1rem;
}

a {
  color: black;
}

#webLink:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: wavy;
          text-decoration-style: wavy;
}

#dumpling {
  font-size: 10px;
}

#designText {
  width: 75%;
  text-align: justify;
}

.grid {
  margin-bottom: 3vw;
}

.screengrab {
  object-fit: contain;
  width: 100%;
}

.box1 {
  border: solid 1px lightgrey;
  margin-bottom: 3vw;
  padding: 1vw;
}

.textBox {
  padding: 4vw;
  font-size: 1rem;
}

@media only screen and (min-width: 600px) {
  .designContainer {
    margin: 4vw 0vw 0vw 4vw;
    margin-top: 10vw;
    font-size: 1rem;
  }

  #designText {
    width: 55%;
    margin-bottom: 5vw;
  }

  .box1 {
    display: flex;
    justify-content: space-between;
    margin-right: 4vw;
  }

  .textBox {
    padding-left: 3vw;
    padding-right: 3vw;
  }

  .screengrab {
    object-fit: contain;
    width: 25%;
    padding: 0.5vw;
  }
}

.welcomeText {
  margin: 12vw 0vw 0vw 4vw;
}

h1 {
  margin-top: 20vw;
  font-size: 3rem;
  font-weight: normal;
}

.meKg {
  font-size: 1.75rem;
  padding-top: 0;
  margin-block-end: 0em;
  margin-block-start: 0em;
  width: 65%;
}

.homeButton a {
  text-decoration: none;
  border: black 1px solid;
  font-size: 1.25rem;
  padding: 1.5vh;
  margin-right: 3vw;
  text-decoration: none;
  color: #000000;
}

.homeButtonContainer {
  padding-bottom: 5vh;
}

.homeButton a:hover {
  background-color: black;
  color: white;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: wavy;
          text-decoration-style: wavy;
}

.homeButtonOuter {
  display: flex;
  flex-direction: column;
  margin-top: 7vh;
  color: #000000;
}

@media only screen and (min-width: 600px) {
  .welcomeText {
    margin: 4vw 0vw 0vw 4vw;
  }

  h1 {
    margin-top: 10vw;
    font-size: 3rem;
    font-weight: normal;
  }

  .meKg {
    font-size: 2rem;
    padding-top: 0;
    margin-block-end: 0em;
    width: 80%;
  }

  .homeButton a {
    text-decoration: none;
    border: black 1px solid;
    font-size: 1rem;
    padding: 1.5vh;
    margin-right: 3vw;
    text-decoration: none;
    color: #000000;
  }

  .homeButtonOuter {
    display: flex;
    flex-direction: row;
    margin-top: 6vh;
    color: #000000;
  }
}

@media only screen and (device-width: 768px) {
  .homeButtonOuter {
    display: flex;
    flex-direction: column;
    margin-top: 6vh;
    color: #000000;
  }
}

.illustrationContainer {
  margin: 12vw 4vw 0vw 4vw;
  margin-top: 20vw;
  font-size: 1rem;
}

#illustrationText {
  width: 85%;
  text-align: justify;
}

.grid {
  margin-bottom: 3vw;
}

.image {
  object-fit: contain;
  width: 100%;
}

.image:hover {
  opacity: 0.7;
  cursor: pointer;
}

.imageBox {
  /* border: lightgrey 1px solid; */
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 4vw;
}

.ril__caption,
.ril__toolbar {
  background-color: rgba(0, 0, 0, 0) !important;
}

.ril__builtinButton {
  background-color: #6d6e70 !important;
}

.ril__zoomOutButton {
  background-color: #6d6e70 !important;
}

.ril__outer {
  background: rgba(255, 255, 255, 1) !important;
}

.ril__navButtonNext {
  background-color: #6d6e70 !important;
}

.ril__navButtonPrev {
  background-color: #6d6e70 !important;
}

.ril__navButtons {
  padding: 30px 20px !important;
}

.ril__loadingContainer__icon {
  color: #6d6e70 !important;
}

@media only screen and (min-width: 600px) {
  .illustrationContainer {
    margin: 4vw 0vw 0vw 4vw;
    margin-top: 10vw;
  }

  #illustrationText {
    width: 55%;
    margin-bottom: 5vw;
    text-align: justify;
  }

  .grid {
    /* margin: auto; */
    display: grid;
    grid-template-columns: 20% 20% 20% 20%;
    /* grid-template-rows: 25% 25% 25%; */
    grid-gap: 5vw 5vw;
    gap: 5vw 5vw;
    margin-bottom: 3vw;
  }

  .image {
    object-fit: contain;
    width: 100%;
  }

  .imageBox {
    justify-content: space-between;
    overflow: hidden;
    margin-bottom: 0vw;
  }
}

.illustrationContainer {
  margin: 12vw 4vw 0vw 4vw;
  margin-top: 20vw;
  font-size: 1rem;
}

#illustrationText {
  width: 85%;
  text-align: justify;
}

.grid {
  margin-bottom: 3vw;
}

.image {
  object-fit: contain;
  width: 100%;
}

.image:hover {
  opacity: 0.7;
  cursor: pointer;
}

.imageBox {
  /* border: lightgrey 1px solid; */
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 4vw;
}

.ril__caption,
.ril__toolbar {
  background-color: rgba(0, 0, 0, 0) !important;
}

.ril__builtinButton {
  background-color: #6d6e70 !important;
}

.ril__zoomOutButton {
  background-color: #6d6e70 !important;
}

.ril__outer {
  background: rgba(255, 255, 255, 1) !important;
}

.ril__navButtonNext {
  background-color: #6d6e70 !important;
}

.ril__navButtonPrev {
  background-color: #6d6e70 !important;
}

.ril__navButtons {
  padding: 30px 20px !important;
}

.ril__loadingContainer__icon {
  color: #6d6e70 !important;
}

@media only screen and (min-width: 600px) {
  .illustrationContainer {
    margin: 4vw 0vw 0vw 4vw;
    margin-top: 10vw;
  }

  #illustrationText {
    width: 55%;
    margin-bottom: 5vw;
    text-align: justify;
  }

  .grid {
    /* margin: auto; */
    display: grid;
    grid-template-columns: 20% 20% 20% 20%;
    /* grid-template-rows: 25% 25% 25%; */
    grid-gap: 5vw 5vw;
    gap: 5vw 5vw;
    margin-bottom: 3vw;
  }

  .image {
    object-fit: contain;
    width: 100%;
  }

  .imageBox {
    justify-content: space-between;
    overflow: hidden;
    margin-bottom: 0vw;
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 0.5rem;
  right: 1.5rem;
  cursor: pointer;
  font-size: 2rem;
  background-color: white;
  color: grey;
}

.logo {
  width: 30vw;
  padding: 10vw 0vw 0vw 4vw;
}

/*----- hamburger menu icon -----*/
.menu-container {
  margin-top: -45px;
}
.navicon {
  margin-top: 20px;
  margin-left: 20px;
}
.menu-container .menu-icon {
  cursor: pointer;
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
  padding-right: 20px;
  height: 50px;
  width: 50px;
}
.menu-container .menu-icon:focus {
  border: 1px dotted grey;
  margin: -1px;
  padding-right: 20px;
  height: 50px;
  width: 50px;
}
*:focus {
  outline: 0 !important;
  border: 0 !important;
}
.menu-container .menu-icon .navicon {
  background: black;
  display: block;
  height: 3px;
  position: relative;
  width: 25px;
}
.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.menu-container .menu-icon .navicon:before,
.menu-container .menu-icon .navicon:after {
  background: black;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}
.menu-container .menu-icon .navicon:before {
  top: 8px;
}
.menu-container .menu-icon .navicon:after {
  top: -8px;
}
/*----- menu btn (triggers opening and closing of hamb. menu) -----*/
.menu-container .menu-btn {
  display: none;
}
.menu-container .menu-btn:checked ~ .menu-outer .menu,
.menu-container .menu-btn:checked ~ .menu-outer .hq-nav {
  max-height: 100%;
}
.menu-container .menu-btn:checked ~ .menu-outer {
  max-height: 75vh;
  margin-top: 6vw;
  width: 100vw;
  box-shadow: 0px 2px 4px 0 rgba(30, 31, 33, 0.5);
}
.menu-outer {
  clear: both;
  overflow-x: auto;
  max-height: 0;
}
.menu-container .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}
.menu-container .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}
.menu-container .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}
.menu-container .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.menu-container .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}
/* End basic hamburger menu setup */
/* --------------------------------- */
.menu-list {
  margin: 0;
  padding: 0;
  overflow: hidden auto;
  list-style: none;
  background-color: white;
}
.main-nav-item a {
  text-align: right;
  color: black;
  display: block;
  padding: 10px 30px;
  text-decoration: none;
}
.main-nav-item:hover,
.hq-nav li:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: wavy;
          text-decoration-style: wavy;
}
.main-nav-item:focus,
.hq-nav .main-nav-item:focus {
  border: 1px dotted #eee;
  margin: -1px;
}

@media only screen and (min-width: 600px) {
  .scroll-to-top {
    bottom: 1rem;
    right: 0.5rem;
    font-size: 2rem;
  }

  .logo {
    width: 8vw;
    padding: 4vw 0vw 0vw 4vw;
  }

  .menu-container .menu-btn:checked ~ .menu-outer {
    margin-top: 3vw;
  }

  .menu-container .menu-icon {
    margin-top: -20px;
    padding-right: 4vw;
  }
  .menu-container .menu-icon:focus {
    padding-right: 4vw;
    margin-top: -20px;
  }
}

@media only screen and (device-width: 768px) {
  .menu-container .menu-icon {
    margin-top: 0px;
    padding-right: 4vw;
  }
  .menu-container .menu-icon:focus {
    padding-right: 4vw;
    margin-top: 0px;
  }
}

.footer {
    font-size: 12px;
    bottom: 0;
    width: 100%;
    text-align: center;
    position: sticky;
    height: 1.5rem;
  }
  
